// src/pages/Newsletter.js
import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PageStyles.css';

const Newsletter = () => {
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');
  const [recipientGroup, setRecipientGroup] = useState('all');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleNewsletter = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'newsletter',
        message,
        attachment,
        buttonText,
        buttonUrl,
        recipientGroup,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setSuccess('Рассылка успешно отправлена');
    } catch (error) {
      setError('Ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin')}>Вернуться назад</Button>
      </div>
      <div className="d-flex flex-column align-items-center">
        <h1>Рассылка</h1>
        <Form onSubmit={handleNewsletter}>
          <Form.Group controlId="message">
            <Form.Label>Сообщение</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Введите текст сообщения"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="attachment" className="mt-3">
            <Form.Label>Ссылка на вложение</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите ссылку"
              value={attachment}
              onChange={(e) => setAttachment(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="buttonText" className="mt-3">
            <Form.Label>Текст кнопки</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите текст кнопки"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="buttonUrl" className="mt-3">
            <Form.Label>URL для кнопки</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите URL"
              value={buttonUrl}
              onChange={(e) => setButtonUrl(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="recipientGroup" className="mt-3">
            <Form.Label>Получатели</Form.Label>
            <Form.Control
              as="select"
              value={recipientGroup}
              onChange={(e) => setRecipientGroup(e.target.value)}
            >
              <option value="all">Всем</option>
              <option value="premium">Премиум</option>
              <option value="test">Тест</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
            {loading ? 'Отправка...' : 'Отправить'}
          </Button>
        </Form>
        {error && <p className="text-danger mt-3">{error}</p>}
        {success && <p className="text-success mt-3">{success}</p>}
      </div>
    </Container>
  );
};

export default Newsletter;
