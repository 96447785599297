// src/pages/Search.js
import React, { useState } from 'react';
import { Button, Container, Form, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PageStyles.css';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'search',
        id: searchTerm,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setUserData(response.data);
    } catch (error) {
      setError('Ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin')}>Вернуться назад</Button>
      </div>
      <div className="d-flex flex-column align-items-center">
        <h1>Поиск</h1>
        <Form onSubmit={handleSearch}>
          <Form.Group controlId="search">
            <Form.Label>Введите ID пользователя или username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите id или username"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-2" disabled={loading}>
            {loading ? 'Поиск...' : 'Поиск'}
          </Button>
        </Form>
        {error && <p className="text-danger mt-3">{error}</p>}
        {userData && (
          <Card className="mt-4 user-info-card">
            <Card.Body>
              <Card.Title className="text-center">Информация о пользователе</Card.Title>
              <div className="api-info">
                <p>ID: {userData.id}</p>
                <p>UID: {userData.uid}</p>
                <p>VK ID: {userData.vkId}</p>
                <p>Username: {userData.username}</p>
                <p>Баланс: {userData.balance}</p>
                <p>Подписка: {userData.premium.id === false ? 'Отсутствует' : `ID: ${userData.premium.id}, Время: ${new Date(userData.premium.time * 1000).toLocaleString()}`}</p>
                <p>Реферальный баланс: {userData.ref.balance}</p>
                <p>Реферальные приглашения: {userData.ref.count}</p>
                <p>Бан: {userData.ban ? 'Да' : 'Нет'}</p>
              </div>
              <Button variant="primary" onClick={() => navigate(`/admin/dialog/${userData.id}`)}>Перейти в диалог</Button>  
            </Card.Body>
          </Card>
        )}
      </div>
    </Container>
  );
};

export default Search;
