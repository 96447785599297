import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Card, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dialog.css';

const Dialog = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [bans, setBan] = useState(false);
  const chatWindowRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.post('https://pay.sverhum.ru/api', {
          method: 'dialogs',
          id: id
        });
        setBan(response.data.ban)
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [id]);
  const fetchMessages = async () => {
    try {
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'dialogs',
        id: id
      });
      setBan(response.data.ban)
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  const ban = async () => {
    try {
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'ban',
        id: id,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setBan(true)

      console.log('Пользователь успешно забанен');
    } catch (error) {
      console.log('Ошибка при выполнении запроса');
    }
  };
  const unBan = async () => {
    try {
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'unban',
        id: id,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setBan(false)

      console.log('Пользователь успешно разбанен');
    } catch (error) {
      console.log('Ошибка при выполнении запроса');
    }
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    try {
      setInput('')
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'send',
        id: id,
        message: input
      });
      setMessages(response.data.messages);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [messages]);

  return (
    <div className='chat-page'>
      <Container fluid className="chat-container">
        <Row className="justify-content-center">
          <div className="back-button-container">
            <Button variant="secondary" onClick={() => navigate('/admin/message')}>Вернуться назад</Button>
          </div>
      
          <Card className="chat-card">
            <Card.Body className="d-flex flex-column">
              <Button variant="primary" onClick={() => fetchMessages()}>Обновить</Button>
              <Card.Title className="text-center">Chat with User {id}</Card.Title>
              <Button variant="primary" onClick={() => bans === false ? ban() : unBan()}>{bans === false ? `Бан` : `Разбан`}</Button>
              <div className="chat-window" ref={chatWindowRef}>
                {messages.map((msg, idx) => (
                  <div key={idx} className={`message ${msg.sender === 'user' ? 'user-message' : 'bot-message'}`}>
                    <span>{msg.text}</span>
                    <div className='msg_date'>{new Date(msg.date).toLocaleString()}</div>
                  </div>
                ))}
              </div>
              <Form onSubmit={sendMessage} className="message-form">
                <Form.Group className="d-flex mb-0">
                  <Form.Control
                    type="text"
                    placeholder="Введите сообщение..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                  <Button variant="primary" type="submit">Отправить</Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default Dialog;
