import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './PageStyles.css';

const Analytics = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  // Получаем текущую дату
  const currentDate = new Date();

  // Определяем названия месяцев для текущего, прошлого и позапрошлого
  const getMonthName = (offset) => {
    let date = new Date();
    date.setMonth(currentDate.getMonth() - offset);
    return date.toLocaleString('ru-RU', { month: 'long' });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://pay.sverhum.ru/api', 
          { method: 'arhiveData' },  
          { headers: { 'Content-Type': 'application/json' } }
        );
        console.log(response.data)
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container className="mt-4 d-flex justify-content-center">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin')}>Вернуться назад</Button>
      </div>
      <Card className="info-card">
        <Card.Body>
          <Card.Title className="text-center mb-4">Информация от API</Card.Title>
          {data ? (
            <div className="api-info">
              {/* Проверка на наличие данных за текущий месяц */}
              {data.currentMonth && (
                <>
                  <h2>{getMonthName(0).charAt(0).toUpperCase() + getMonthName(0).slice(1)}</h2>
                  <Row>
                    <Col>
                      <p className="info-label">Зарегистрировано:</p>
                      <p className="info-value">{data.currentMonth.registered || 0}</p>
                      <p className="info-label">Успешные платежи:</p>
                      <ul className="subscription-list">
                        <li><span className="subscription-type">Start:</span> <span className="subscription-value">{data.currentMonth.payments.start || 0}</span></li>
                        <li><span className="subscription-type">Master:</span> <span className="subscription-value">{data.currentMonth.payments.master || 0}</span></li>
                        <li><span className="subscription-type">Year:</span> <span className="subscription-value">{data.currentMonth.payments.year || 0}</span></li>
                      </ul>
                    </Col>
                  </Row>
                </>
              )}

              {/* Проверка на наличие данных за предыдущий месяц */}
              {data.previousMonth && (
                <>
                  <h2>{getMonthName(1).charAt(0).toUpperCase() + getMonthName(1).slice(1)}</h2>
                  <Row>
                    <Col>
                      <p className="info-label">Зарегистрировано:</p>
                      <p className="info-value">{data.previousMonth.registered || 0}</p>
                      <p className="info-label">Успешные платежи:</p>
                      <ul className="subscription-list">
                        <li><span className="subscription-type">Start:</span> <span className="subscription-value">{data.previousMonth.payments.start || 0}</span></li>
                        <li><span className="subscription-type">Master:</span> <span className="subscription-value">{data.previousMonth.payments.master || 0}</span></li>
                        <li><span className="subscription-type">Year:</span> <span className="subscription-value">{data.previousMonth.payments.year || 0}</span></li>
                      </ul>
                    </Col>
                  </Row>
                </>
              )}

              {/* Проверка на наличие данных за позапрошлый месяц */}
              {data.monthBeforeLast && (
                <>
                  <h2>{getMonthName(2).charAt(0).toUpperCase() + getMonthName(2).slice(1)}</h2>
                  <Row>
                    <Col>
                      <p className="info-label">Зарегистрировано:</p>
                      <p className="info-value">{data.monthBeforeLast.registered || 0}</p>
                      <p className="info-label">Успешные платежи:</p>
                      <ul className="subscription-list">
                        <li><span className="subscription-type">Start:</span> <span className="subscription-value">{data.monthBeforeLast.payments.start || 0}</span></li>
                        <li><span className="subscription-type">Master:</span> <span className="subscription-value">{data.monthBeforeLast.payments.master || 0}</span></li>
                        <li><span className="subscription-type">Year:</span> <span className="subscription-value">{data.monthBeforeLast.payments.year || 0}</span></li>
                      </ul>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          ) : (
            <p>Загрузка...</p>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Analytics;
