// src/components/ButtonGroup.js
import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ButtonGroup.css';

const ButtonGroup = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="button-groups">
      <Row className="justify-content-center mb-2">
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/search')}>Поиск</Button>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/analytics')}>Статистика</Button>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/give-subscription')}>Выдать подписку</Button>
        </Col>
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/take-subscription')}>Забрать подписку</Button>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/ban')}>Бан</Button>
        </Col>
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/unban')}>Разбан</Button>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/givetoken')}>Выдать token</Button>
        </Col>
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/taketoken')}>Забрать token</Button>
        </Col>
      </Row>
      <Row className="justify-content-center mb-2">
        <Col xs="auto" className="mb-2">
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/newsletter')}>Рассылка</Button>
          <Button variant="primary" className="custom-button" onClick={() => handleNavigation('/admin2/partner')}>Партнеры</Button>
          </Col>
      </Row>
    </div>
  );
};

export default ButtonGroup;
