// src/pages/Messages.js
import React, { useState, useEffect } from 'react';
import { Button, Container, ListGroup, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PageStyles.css';

const Messages = () => {
  const [dialogs, setDialogs] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDialogs = async () => {
      try {
        const response = await axios.post('https://pay.sverhum.ru/api', {
          method: 'dialogs',
        }, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response.data) {
          setDialogs(response.data);
        } else {
          setError('Диалоги отсутствуют');
        }
      } catch (error) {
        setError('Ошибка при получении диалогов');
        console.error('Error fetching dialogs:', error);
      }
    };

    fetchDialogs();
  }, []);
  const fetchDialogs = async () => {
    try {
      const response = await axios.post('https://pay.sverhum.ru/api', {
        method: 'dialogs',
      }, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data) {
        setDialogs(response.data);
      } else {
        setError('Диалоги отсутствуют');
      }
    } catch (error) {
      setError('Ошибка при получении диалогов');
      console.error('Error fetching dialogs:', error);
    }
  }
  return (
    <Container className="mt-4">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin')}>Вернуться назад</Button>
      <Button variant="primary" onClick={() => fetchDialogs()}>Обновить</Button>  
      </div>
      <h1>Диалоги</h1>
      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <ListGroup>
          {dialogs.map((dialog) => (
            <ListGroup.Item key={dialog.id}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p>{dialog.first_name} (@{dialog.username}) - {dialog.id}</p>
                </div>
                <div className='msg_date'>{new Date(dialog.time).toLocaleString()}</div>
                <Button variant="primary" onClick={() => navigate(`/admin/dialog/${dialog.id}`)}>Открыть диалог</Button>
              </div>
            </ListGroup.Item>
          ))}
          {dialogs.length === 0 && !error && (
            <Alert variant="info">Диалоги отсутствуют</Alert>
          )}
        </ListGroup>
      )}
    </Container>
  );
};

export default Messages;
